<template>
    <BaseToxinsUsed 
        :key="versionkeyreload"
        :ToxinsUsed="payload" 
        :entrycode="entrycode" 
        :passed="true" />
</template>

<script>
import { get } from 'vuex-pathify'

import UnsavedGuardMixin from '@/mixins/UnsavedGuard.js'
import PassEntrycode from '@/mixins/PassEntrycode.js'

export default {
    mixins: [UnsavedGuardMixin, PassEntrycode],
    computed: {
        ...get('version', {
            versionkeyreload: 'reload'
        }),
        payload() {
            let _payload = this.$store.state.incidentsnew.incident.data.ToxinsUsed
            return _payload
        }
    }
}
</script>
